.generalCard_input
    margin-right: 24px !important

.action_button
    margin-right: 12px !important

.general_nav_row
    position: absolute
    top: 0
    right: 0
    transform: translate(-26px, 24px)
